import React from 'react';
import { Waves as WavesIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const GradientWaveIcon = styled(WavesIcon)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.accent1.main}, ${theme.palette.accent2.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

export default GradientWaveIcon;
