import React from 'react';
import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import GradientWavesIcon from './GradientWavesIcon'; // Ensure correct import path

const CustomAppBar = ({ showRegister, showLogin }) => {
  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.primary.main,
    theme.palette.accent4.main,
  ];

  const AppBarText = ({ parts, colors }) => {
    const coloredText = parts.map((part, index) => (
      <span key={index} style={{ color: colors[index % colors.length] }}>
        {part}
      </span>
    ));
    return <span>{coloredText}</span>;
  };

  return (
    <AppBar position="static" color="transparent" elevation={0} sx={{ zIndex: 2, paddingLeft: 4, paddingRight: 4 }}>
      <Toolbar>
        <RouterLink to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
          <GradientWavesIcon color="primary" sx={{ fontSize: 25, mr: 1 }} />
          <Typography color="primary" variant="h4" sx={{ flexGrow: 1 }}>
            <AppBarText parts={['data', 'wave', 'IQ']} colors={colors} />
          </Typography>
        </RouterLink>
        <div style={{ flexGrow: 1 }} />
        {/* <RouterLink to="/audio-iq" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="primary" sx={{ textTransform: 'none' }}>
            AudioIQ
          </Button>
        </RouterLink>
        <RouterLink to="/image-iq" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="primary" sx={{ textTransform: 'none' }}>
            ImageIQ
          </Button>
        </RouterLink>
        <RouterLink to="/culture-pulse-iq" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="primary" sx={{ textTransform: 'none' }}>
            CultureIQ
          </Button>
        </RouterLink> */}
        {showLogin && (
          <Button color="primary" href="#waitlist-section" sx={{ textTransform: 'none' }}>
            Join Beta
          </Button>
        )}
        {/* {showRegister && (
          <Button color="primary" href="/register" variant="contained" sx={{ ml: 2, textTransform: 'none' }}>
            Register
          </Button>
        )} */}
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
