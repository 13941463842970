// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); // New state for user role
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      try {
        console.log('Setting persistence...');
        await setPersistence(auth, browserLocalPersistence);
        console.log('Persistence set.');
        
        onAuthStateChanged(auth, async (user) => {
          console.log('Auth state changed:', user);
          if (user) {
            const token = await user.getIdTokenResult();
            const userRole = token.claims.role || 'User'; // Adjust based on your role management logic
            setUser(user);
            setRole(userRole); // Set the role in state
          } else {
            setUser(null);
            setRole(null);
          }
          setLoading(false); // Set loading to false once auth state is known
        });
      } catch (error) {
        console.error('Failed to set persistence:', error);
        setLoading(false); // Ensure loading is set to false even if there's an error
      }
    };

    initAuth();

    return () => {
      // Cleanup listener on unmount
      auth.onAuthStateChanged(() => {});
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, role, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
