// src/roleConfig.js
export const roleConfig = {
    '/admin/dashboard': ['Admin'],
    '/dashboard': ['Free', 'Hobbyist', 'Pro', 'Admin'],
    '/duplicates': ['Hobbyist', 'Pro', 'Enterprise'],
    '/face-detection': ['Hobbyist', 'Pro', 'Admin'],
    '/caption-generator': ['Hobbyist', 'Pro', 'Admin'],
    '/metadata-extractor': ['Hobbyist', 'Pro', 'Admin'],
    '/metadata-setter': ['Hobbyist', 'Pro', 'Admin'],
    '/corrupted-files': ['Free', 'Hobbyist', 'Pro', 'Admin'],
    '/resize-images': ['Free', 'Hobbyist', 'Pro', 'Admin'],
    '/convert-images': ['Free', 'Hobbyist', 'Pro', 'Admin'],
    '/split-dataset': ['Hobbyist', 'Pro', 'Admin'],
    '/batch-images': ['Free', 'Hobbyist', 'Pro', 'Admin'],
    '/upload-dataset-to-s3': ['Hobbyist', 'Pro', 'Admin'],
    '/databricks-aws-auth': ['Hobbyist', 'Pro', 'Admin'],
    '/complete-profile': ['Free', 'Hobbyist', 'Pro', 'Admin'],
  };