import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0073E6',
      semiTrans: '#0073E6d6' // Primary color
    },
    secondary: {
      main: '#565554', // Secondary color
    },
    accent1: {
      main: '#F24236', // Accent color
    },
    accent2: {
      main: '#44AF69', // Accent color
    },
    accent3: {
      main: '#F7B538', // Accent color
    },
    accent4: {
      main: '#00E6E6',
      semiTrans: '#00E6E68f' // Accent color
    },
    accent5: {
      main: '#585DBF'
    },
    text: {
      primary: '#1C2025',
      secondary: '#5C6C7C',
      secondaryDark: '#3B4D5B',
    },
    background: {
      default: '#FFFFFF',
      offWhite: '#F9F9F9',
    },
  },
  typography: {
    fontFamily: 'Righteous, Poppins, sans-serif',
    title: {
      fontSize: '4rem',
      fontWeight: 'bold',
    },
    h1: {
      fontSize: '4rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;

// #0073E6 #00E6E6 FIRST BLUE GRADIENT

// const theme = createTheme({
//   palette: {
//     mode: 'light',  // Ensuring light mode
//     primary: {
//       main: '#90caf9',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#f48fb1',
//       contrastText: '#ffffff',
//     },
//     background: {
//       default: '#f5f5f5',  // Light background color
//       paper: '#ffffff',  // Light paper color
//     },
//     text: {
//       primary: '#000000',  // Dark text color for readability in light mode
//       secondary: '#424242',
//     },
//   },
// });

// export default theme;

// const theme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#90caf9',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#f48fb1',
//       contrastText: '#ffffff',
//     },
//     background: {
//       default: '#303030',
//       paper: '#424242',
//     },
//     text: {
//       primary: '#ffffff',
//       secondary: '#b0bec5',
//     },
//   },
// });

// export default theme;

