// src/context/UserProfileContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import { useAuth } from './AuthContext'; // Import useAuth to get the auth state

export const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth(); // Get the user and loading state from AuthContext
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!authLoading && user) { // Only fetch profile if auth is not loading and user is authenticated
        try {
          console.log('Fetching profile...');
          const response = await axiosInstance.get('/api/profile/');
          console.log('Profile response:', response.data);
          setProfile(response.data.profile);
        } catch (err) {
          console.error('Error fetching profile:', err);
          setError('Error fetching profile');
        } finally {
          setLoading(false);
        }
      } else if (!authLoading && !user) {
        setLoading(false); // No user is authenticated
      }
    };

    fetchProfile();
  }, [authLoading, user]);

  return (
    <UserProfileContext.Provider value={{ profile, setProfile, loading, error }}>
      {children}
    </UserProfileContext.Provider>
  );
};
