// src/axiosConfig.js
import axios from 'axios';
import { auth } from './firebase';

// Utility function to get the CSRF token from the cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const axiosInstance = axios.create({
  baseURL: 'http://localhost:8000', // Your Django backend URL
  withCredentials: true, // Ensure cookies (including CSRF token) are sent with requests
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      console.log('Sending token:', token);
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add CSRF token to the headers if it's a state-modifying request
    const method = config.method.toLowerCase();
    if (['post', 'put', 'delete', 'patch'].includes(method)) {
      const csrfToken = getCookie('csrftoken');
      if (csrfToken) {
        config.headers['X-CSRFTOKEN'] = csrfToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
